<template>
  <div>
    <component :is="dynamicComponent" />
  </div>
</template>

<script>
export default {
  props: ['integrationName'],
  computed: {
    dynamicComponent() {
      return () => import(`@/views/pages/integrations/${this.integrationName}/Edit`)
    },
  },
}
</script>
